import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import NotificationCampaignForm from "./NotificationCampaignForm";

function NotificationCampaign() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, error, isFetching }: any = useItem(
    "notifications-campaigns",
    id
  );

  useEffect(() => {
    if (error) {
      setAlert(t("common.error"), "danger");
    }
  }, [error, setAlert, t]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/notification-campaigns");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataNotificationCampaign: any) => {
      const notificationCampaign = { ...dataNotificationCampaign };

      try {
        await saveItem(notificationCampaign, false);
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [back, saveItem]
  );

  return isFetching ? null : <NotificationCampaignForm onSubmit={onSubmit} />;
}

export default NotificationCampaign;
