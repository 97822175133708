import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useAuth from "common/hooks/useAuth";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import {
  isMailCorrect,
  isPasswordCorrect,
} from "common/components/Password/Password";
import useRouter from "common/hooks/use-router";
import EditHeader from "common/components/EditHeader/EditHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import RadioBlockItem from "common/components/RadioBlockItem/RadioBlockItem";
import rolesData from "conf/roleTypesData";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import { User } from "@sentry/react";
import fixFinalFormEmptyValues from "utils/fixFinalFormEmptyValues";
import { Review } from "types/Review";
import { CurrencyEuroIcon, UserIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Status from "common/components/Status/Status";

function UserForm({ onSubmit, editedUser, myAccount }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { navigate } = useRouter();
  const [changePassword, setChangePassword] = useState(false);

  const initialValues: User = useMemo(
    () =>
      editedUser?.id
        ? {
            ...editedUser,
            role: editedUser?.role?.id ?? 1,
            role_name: editedUser?.role?.name,
          }
        : {
            username: undefined,
            password: undefined,
            role: 1,
            email: "",
            phone: "",
            address: "",
            city: "",
            description: "",
            blocked: false,
            confirmed: true,
            isCompany: false,
            nbChapchap: 3,
            nbExchange: 3,
            nbSafeMeetingPlace: 3,
          },
    [editedUser]
  );

  const checkPassword = (value: string) =>
    isPasswordCorrect(value) ? undefined : t("common.passwordTooWeak");

  const checkEmail = (value: string) =>
    isMailCorrect(value) ? undefined : t("forms.badEmail");

  const validatePasswords = (values: any) => {
    if (values.password !== values.confirmPassword) {
      return { confirmPassword: t("common.resetPasswordError") };
    }
    return {};
  };

  const onSubmitWrapper = async (values: User) => {
    const res = await onSubmit(fixFinalFormEmptyValues(initialValues, values));

    return res;
  };

  const displayRoleChoice =
    !myAccount && currentUser?.isAdmin && !(editedUser?.id > 0);

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      validate={(values: any) =>
        !editedUser ? validatePasswords(values) : undefined
      }
      render={({ handleSubmit, values }) => (
        <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={
              myAccount
                ? `${t("titlePages.myAccount")}`
                : t(`common.${editedUser && editedUser.id ? "edit" : "add"}`)
            }
            subTitle={
              editedUser?.id
                ? `${values.username} - ${values.firstname || ""} ${
                    values.lastname || ""
                  }`
                : t("newItem.userLegend")
            }
            icon="UsersIcon"
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={myAccount ? null : () => navigate("/users")}
              />
            }
          />
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FormScrollError />

            <div className="responsive-flex gap-2 mt-2">
              <div className="md:w-2/3 flex flex-col gap-2">
                <div className="white-box">
                  {displayRoleChoice && (
                    <>
                      <SectionTitle
                        icon="CalendarDaysIcon"
                        title={t("forms.role")}
                      />
                      <FormControl
                        type="custom"
                        name="role"
                        inputContainerStyle="flex gap-3 mb-4"
                        containerStyle="w-full"
                      >
                        <RadioBlock
                          Component={RadioBlockItem}
                          items={rolesData.filter(
                            (role) =>
                              role.value !== "SuperAdmin" ||
                              currentUser?.role?.name === "SuperAdmin"
                          )}
                          horizontal
                        />
                      </FormControl>
                    </>
                  )}
                  <div className="responsive-flex gap-4">
                    <div className="flex flex-col w-full md:w-[180px]">
                      <FormControl
                        title={t("forms.avatar")}
                        labelIcon="PhotoIcon"
                        name="avatar"
                        type="image"
                        containerStyle="mb-2"
                      />
                      <div className="text-xs text-center w-full px-1 pt-0 pb-4">
                        {t("forms.subscribedThe", {
                          since: dayjs(values?.createdAt).format("DD/MM/YYYY"),
                        })}
                      </div>

                      <FormControl
                        type="custom"
                        name="verified"
                        containerStyle="my-2"
                      >
                        <ToggleSwitch
                          rightLabel={t("common.verifiedProfile")}
                          isDisabled
                        />
                      </FormControl>

                      <FormControl
                        type="custom"
                        name="blocked"
                        containerStyle="my-2"
                      >
                        <ToggleSwitch
                          rightLabel={t("forms.blocked")}
                          isDisabled={currentUser?.id === values.id}
                        />
                      </FormControl>
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <FormControl
                        name="username"
                        titleKey={t("forms.username")}
                        required
                      />
                      <FormControl
                        name="firstname"
                        titleKey={t("forms.firstname")}
                        required
                      />
                      <FormControl
                        name="lastname"
                        titleKey={t("forms.lastname")}
                        required
                      />
                      <FormControl
                        name="phone"
                        titleKey={t("forms.phone")}
                        required
                      />
                      <FormControl
                        name="address"
                        titleKey={t("forms.address")}
                      />
                      <FormControl name="city" titleKey={t("forms.city")} />
                      <FormControl
                        type="textarea"
                        name="companyDescription"
                        titleKey={t("forms.companyDescription")}
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="white-box flex flex-col gap-4">
                  <div className="responsive-flex ">
                    <FormControl type="custom" name="isCompany">
                      <ToggleSwitch rightLabel={t("forms.hasCompany")} />
                    </FormControl>
                  </div>
                  {values.isCompany && (
                    <div className="responsive-flex gap-4">
                      <div className="flex flex-col w-full md:w-[180px]">
                        <FormControl
                          title={t("forms.companyLogo")}
                          labelIcon="PhotoIcon"
                          name="companyLogo"
                          type="image"
                        />
                      </div>
                      <div className="flex flex-col w-full gap-4">
                        <FormControl
                          name="companyName"
                          titleKey={t("forms.companyName")}
                          required
                        />
                        <FormControl
                          name="companyCCM"
                          titleKey={t("forms.companyCCM")}
                          required
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:w-1/3 gap-2">
                <div className="white-box">
                  <div className="flex flex-col w-full gap-2">
                    <SectionTitle
                      title={t("forms.loginInfos")}
                      icon="CalendarDaysIcon"
                    />

                    <FormControl
                      name="email"
                      titleKey={t("forms.email")}
                      required
                      validate={checkEmail}
                      // containerStyle="mb-2"
                    />

                    {!!editedUser?.id && (
                      <div className="my-2">
                        <ToggleSwitch
                          rightLabel={t("forms.newPassword")}
                          value={changePassword}
                          onChange={setChangePassword}
                        />
                      </div>
                    )}
                    {(changePassword || !editedUser?.id) && (
                      <>
                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                          <FormControl
                            type="jpassword"
                            name="password"
                            titleKey={t("forms.password")}
                            required
                            validate={checkPassword}
                          />
                          <FormControl
                            name="confirmPassword"
                            title={t("forms.confirmPassword")}
                            type="password"
                          />
                        </div>
                        {t("common.passwordInstruction")}
                      </>
                    )}
                  </div>
                </div>

                <div className="white-box">
                  <div className="flex flex-col w-full gap-2">
                    <SectionTitle
                      title={t("forms.quotas")}
                      icon="RiNUmbersLine"
                    />
                    <FormControl
                      name="nbExchange"
                      titleKey={t("forms.exchange")}
                      containerStyle="grid grid-cols-2 gap-2"
                    />
                    <FormControl
                      name="nbSafeMeetingPlace"
                      titleKey={t("forms.safeMeetingPlace")}
                      containerStyle="grid grid-cols-2 gap-2"
                    />
                    <FormControl
                      name="nbChapchap"
                      titleKey={t("forms.chapchap")}
                      containerStyle="grid grid-cols-2 gap-2"
                    />
                  </div>
                </div>

                <div className="white-box flex flex-col gap-8">
                  <h2 className="flex flex-row justify-between text-lg font-semibold">
                    Note globale :
                    <div className="flex flex-row gap-1 items-center">
                      <StarIcon width={25} className="text-yellow-500" />

                      <p className="text-gray-600 leading-6 text-xl">
                        <b className="text-gray-900">{editedUser?.rating}</b>
                        /5
                      </p>
                    </div>
                  </h2>
                  {editedUser?.reviews?.map((review: Review) => {
                    return (
                      <div key={`review-${review.id}`}>
                        <div className="flex flex-row items-start gap-4">
                          <div className="w-12 h-12 rounded-lg overflow-hidden">
                            <ImageComponent
                              image={
                                review.author?.avatar
                                  ? ImageFromStrapiMedia(review.author?.avatar)
                                      ?.uri
                                  : undefined
                              }
                            />
                          </div>

                          <div className="flex-1 gap-1">
                            <div className="flex flex-row justify-between gap-1.5">
                              <div className="flex flex-row gap-1 items-center">
                                <StarIcon
                                  width={25}
                                  className="text-yellow-500"
                                />

                                <p className="text-gray-600 leading-6 text-xl">
                                  <b className="text-gray-900">
                                    {review.ratings}
                                  </b>
                                  /5
                                </p>
                              </div>

                              <Status status={review.type} />
                            </div>

                            <p className="mb-1 font-semibold">
                              {review.comment}
                            </p>

                            <p className="mt-2 flex flex-row gap-4">
                              <UserIcon width={24} className="" />
                              <Link to={`/users/edit/${review.author?.id}`}>
                                <span className="border-b-2 border-slate-500 hover:border-emerald-500 text-slate-800">
                                  {review.author?.username}
                                </span>
                              </Link>
                              , le{" "}
                              {dayjs(review.createdAt).format("DD/MM/YYYY")}
                            </p>
                            <p className="mt-2 flex flex-row gap-4">
                              <CurrencyEuroIcon width={24} className="" />
                              <Link to={`/transactions/${review.author?.id}`}>
                                <span className="border-b-2 border-slate-500 hover:border-emerald-500 text-slate-800">
                                  {review.transaction?.content?.title}
                                </span>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={myAccount ? null : () => navigate("/users")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default UserForm;
