import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import useRouter from "common/hooks/use-router";
import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import { User } from "@sentry/react";
import { NotificationCampaign } from "types/NotificationCampaign";
import SectionTitle from "common/components/SectionTitle/SectionTitle";

function NotificationCampaignForm({ onSubmit }: any) {
  const { t } = useTranslation();
  const { navigate } = useRouter();

  const initialValues: User = useMemo(() => {
    return {
      path: "/",
      filters: {
        verified: "both",
        isCompany: "both",
        withAccount: "both",
      },
    };
  }, []);

  const onSubmitWrapper = async (values: NotificationCampaign) => {
    const res = await onSubmit(values);

    return res;
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={t("newItem.notificationCampaign")}
            subTitle={values.title}
            icon="BellAlertIcon"
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={() => {
                  navigate("/notification-campaigns");
                }}
              />
            }
          />
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FormScrollError />

            <div className="responsive-flex gap-2 mt-2">
              <div className="white-box flex-1">
                <div className="flex flex-col gap-2">
                  <SectionTitle
                    icon="BellAlertIcon"
                    title={t("forms.notification")}
                  />
                  <FormControl
                    name="title"
                    titleKey={t("forms.notificationTitle")}
                    required
                  />
                  <FormControl
                    name="description"
                    titleKey={t("forms.notificationDescription")}
                    required
                  />
                  <FormControl
                    name="path"
                    titleKey={t("forms.notificationPath")}
                    required
                  />
                </div>
              </div>
              <div className="white-box">
                <div className="flex flex-col gap-2">
                  <SectionTitle icon="UserIcon" title={t("forms.users")} />
                  <FormControl
                    name="filters.withAccount"
                    titleKey={t("forms.notificationFiltersWithAccount")}
                    type="buttongroup"
                    options={[
                      { value: "withAccount", label: t("forms.withAccount") },
                      {
                        value: "withoutAccount",
                        label: t("forms.withoutAccount"),
                      },
                      { value: "both", label: t("forms.both") },
                    ]}
                    required
                  />
                  {(values?.filters as any)?.withAccount !==
                    "withoutAccount" && (
                    <FormControl
                      name="filters.verified"
                      titleKey={t("forms.notificationFiltersVerified")}
                      type="buttongroup"
                      options={[
                        { value: "verified", label: t("forms.verified") },
                        { value: "nonVerified", label: t("forms.nonVerified") },
                        { value: "both", label: t("forms.both") },
                      ]}
                      required
                    />
                  )}
                  {(values?.filters as any)?.withAccount !==
                    "withoutAccount" && (
                    <FormControl
                      name="filters.isCompany"
                      titleKey={t("forms.notificationFiltersIsCompany")}
                      type="buttongroup"
                      options={[
                        {
                          value: "notificationIsCompany",
                          label: t("forms.isCompany"),
                        },
                        {
                          value: "notificationIsNotCompany",
                          label: t("forms.isNotCompany"),
                        },
                        { value: "both", label: t("forms.both") },
                      ]}
                      required
                    />
                  )}
                </div>
              </div>
            </div>
            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={() => navigate("/notification-campaigns")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default NotificationCampaignForm;
