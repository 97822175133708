import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";

import Button from "common/components/Button/Button";
import truncateString from "common/utils/truncateString";
import Icon from "components/Icon/Icon";
import getFlattenText from "common/utils/getFlattenText";
import useAuth from "common/hooks/useAuth";
import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";
import dayjs from "dayjs";
import { useMemo } from "react";
import Status from "common/components/Status/Status";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { Report } from "types/Report";

const containerStyle =
  "flex px-2 py-2 mb-2 w-full relative cursor-pointer rounded-lg border transition-all overflow-hidden";

function EmergencyItem({ item, onDelete }: any) {
  const {
    id,
    author,
    user,
    content,
    transaction,
    createdAt,
    state,
    typeContent,
  } = item as Report;

  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const detailLink = `/emergencies/${id}`;

  const reportedAuthor = useMemo(
    () => content?.author || transaction?.buyer || null,
    [content, transaction]
  );

  const itemDateInfos = useMemo(() => {
    if (transaction) {
      return t("emergencies.createdOn", {
        date: dayjs(transaction?.createdAt).format("DD/MM/YYYY"),
      });
    }
    if (user) {
      return t("emergencies.createdOn", {
        date: dayjs(user?.createdAt).format("DD/MM/YYYY"),
      });
    }
    if (content) {
      if (content?.status === "published") {
        return t("emergencies.publishedFromTo", {
          from: dayjs(content?.publishedDate).format("DD/MM/YYYY"),
          to: dayjs(content?.endPublishedDate).format("DD/MM/YYYY"),
        });
      }
      return t("forms.notPublishedYet");
    }
    return null;
  }, [content, transaction, t, user]);

  return !typeContent ? null : (
    <div
      className={`${containerStyle} ${
        state === "processed"
          ? "bg-primary-50 border-slate-150"
          : "bg-white border-slate-300"
      } items-start lg:items-stretch flex-col lg:flex-row`}
    >
      <Link to={detailLink} className="w-full">
        <div
          className={`responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4 ${
            state === "processed" ? " opacity-60" : " opacity-100"
          }`}
        >
          <div className="flex items-start justify-center relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
            <span
              className={`flex flex-row items-center p-1 border leading-[0] text-md rounded ${
                state === "processed"
                  ? "bg-white border-primary-400 text-primary-500"
                  : "bg-white border-primary text-primary"
              }`}
            >
              <TooltipWrapper
                className="mr-1 my-1"
                content={<span>{typeContent?.name}</span>}
                place="top"
              >
                <Icon name={typeContent?.icon} className="w-8 h-8 ml-1" />
              </TooltipWrapper>
            </span>
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div
              className={`${
                state === "unprocessed" ? "font-bold" : "font-normal"
              } flex flex-row w-full items-center gap-4 flex-wrap font-bold text-primary`}
            >
              <span className="text-lg">
                {t("emergencies.reportedItemOfType")}
              </span>

              <h4 className="text-sm text-slate-500 leading-6 flex flex-row items-center flex-grow justify-end">
                <Icon name="RequestIcon" className="w-6 h-6 ml-1" />
                <TooltipWrapper
                  className="mr-1 my-1"
                  content={<span>{author?.email}</span>}
                  place="top"
                >
                  {author?.username ?? "Auteur non renseigné"}
                </TooltipWrapper>
              </h4>
            </div>

            <div className="responsive-flex-lg mt-1" />

            {/* foot */}
            <div className="flex flex-row justify-start gap-2 items-center pt-1 border-t border-slate-200">
              <div className="flex relative w-full h-40 lg:w-16 lg:h-16 shrink-0">
                <div className="">
                  {content ? (
                    <ImageComponent
                      image={
                        content?.images?.[0]
                          ? ImageFromStrapiMedia(content.images[0])?.uri
                          : undefined
                      }
                    />
                  ) : user ? (
                    <UserAvatar
                      user={user}
                      growOnSmallDevice={false}
                      forcedSize="w-16 h-16"
                      forcedCompanyLogoSize="w-8 h-8"
                    />
                  ) : transaction ? (
                    <UserAvatar
                      user={transaction?.buyer}
                      growOnSmallDevice={false}
                    /> // TODO: finish when transaction implemented
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row items-center gap-1 text-sm text-gray-500 font-bold">
                  <Icon name={typeContent.icon} className="w-4 h-4 ml-1" />

                  {truncateString(getFlattenText(typeContent.label), 150)}
                </div>
                {reportedAuthor && (
                  <div className="flex flex-row items-center gap-1 text-sm text-gray-500">
                    <Icon name="UserIcon" className="w-4 h-4 ml-1" />
                    <TooltipWrapper
                      className="mr-1 my-1"
                      content={<span>{reportedAuthor?.email}</span>}
                      place="top"
                    >
                      {reportedAuthor?.username}
                    </TooltipWrapper>
                  </div>
                )}
                {itemDateInfos && (
                  <div className="flex flex-row items-center gap-1 text-sm text-gray-500">
                    <Icon name="ClockIcon" className="w-4 h-4 ml-1" />
                    {itemDateInfos}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* right */}
      <div className="flex flex-col justify-between text-xs w-full lg:border-l lg:pl-4 lg:w-[30em] lg:border-slate-150 gap-2">
        <div className="flex w-full justify-between items-center text-slate-500">
          <span className="text-sm font-bold">
            {dayjs(createdAt).format("DD/MM/YYYY")}
          </span>
          {state ? <Status status={state} /> : <div />}
        </div>
        {currentUser?.isJuloaAdmin && (
          <div className="flex w-full gap-1 justify-end">
            <Button
              type="error-line"
              icon="TrashIcon"
              compact
              onClick={() => onDelete(item.id)}
              confirmMessage={t("actions.confirmDelete")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EmergencyItem;
