import useList from "common/hooks/useList";
import { createContext, useCallback, useMemo } from "react";
import { Category } from "types/Category";
import { Field } from "types/Field";

type ProviderFieldContextType = {
  fields?: Field[] | null;
  refreshFields: any;
  isFetching: boolean;
  error: string | null;
  getFieldsByFieldNames: (names: string[], category: Category) => Field[];
};

export const FieldsContext = createContext<ProviderFieldContextType>(
  {} as ProviderFieldContextType
);

type Props = {
  children: React.ReactNode;
};

export function FieldsProvider({ children }: Props) {
  const {
    items: fields,
    fetchItems,
    isFetching,
    error,
  } = useList<Field>("fields/data/all");

  const getFieldsByFieldNames = useCallback(
    (names: string[], category: Category) => {
      if (names.length && fields?.length && category && category?.id) {
        return fields.filter(
          (f: Field) =>
            names.includes(f.name) &&
            f.categories?.find((c) => category?.id === c.id)
        );
      }
      return [];
    },
    [fields]
  );

  const contextValue = useMemo(
    () => ({
      fields,
      refreshFields: fetchItems,
      isFetching,
      error,
      getFieldsByFieldNames,
    }),
    [fields, fetchItems, isFetching, error, getFieldsByFieldNames]
  );

  return (
    <FieldsContext.Provider value={contextValue}>
      {children}
    </FieldsContext.Provider>
  );
}
